import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { IPeriodSelectorMode } from '#/Pages/Expenses/Overview/Components/ExpensesTopBar/PeriodSelector/interfaces'
import { ROUTE_NAMES } from '#layouts/Authorized/interfaces'
import { routes } from '#layouts/Authorized/routes'
import { parseDate } from '#lib/Formatters/DateFormatter'
import {
  IFilterStatusEnum,
  ITaskEnum,
  useGetMobilityEventMenuInformationLazyQuery,
  useGetTaskListQuery,
} from '#lib/graphql'
import { TFilterEvents } from '#providers/MobilityEventsProvider/interfaces'
import { useFilterHelper } from '#providers/MobilityEventsProvider/useFilterHelper'

import { Typography } from '../Typography'
import { IProps, TGroupedTasks, TTask } from './interfaces'
import Styles from './styles.module.scss'
import { Task } from './Task'

export const TaskListComponent: React.FC<IProps> = ({
  displayTitle = true,
  tasks,
  loading,
  error,
  confirmableMobilityEventsCount = 0,
}): JSX.Element | null => {
  const { t } = useTranslation()
  const { navigateAndStoreFilterOptions } = useFilterHelper()
  const [getMenuInformation] = useGetMobilityEventMenuInformationLazyQuery()

  if (loading) return <Skeleton height={220} />
  if (error) return null

  const categorisedTasks =
    tasks?.reduce<TGroupedTasks>((acc, task) => {
      const { type } = task

      if (type === ITaskEnum.MOBILITY_EVENTS_WEEK) return acc

      const tasksOfCurrentType = acc[type]
      const dataToAttach = tasksOfCurrentType ? [...tasksOfCurrentType, task] : [task]

      return Object.assign(acc, { [type]: dataToAttach })
    }, {}) || {}

  const getModifiedProps = (task: TTask): { path?: string } => {
    switch (task.__typename) {
      case 'NewProduct':
      case 'ActivatableDongle':
      case 'ActionRequired':
        return { path: routes[ROUTE_NAMES.CARDS_AND_SERVICES] }
      case 'PendingApproval':
        return { path: routes[ROUTE_NAMES.APPROVALS] }
      case 'RefundToBeLinked':
        return { path: `/mobility_events/${task.mobilityEventId}` }
      default:
        return {}
    }
  }

  const openDeclarationsHandler = (): void => {
    getMenuInformation()
      .then((response) => {
        const activeSince = response.data?.me?.profile.activeSince

        if (!activeSince) return

        const statuses = [IFilterStatusEnum.DRAFT, IFilterStatusEnum.REJECTED]
        const filter: TFilterEvents = {
          dates: {
            period: {
              from: parseDate(activeSince),
              to: new Date(),
            },
            mode: IPeriodSelectorMode.CUSTOM,
          },
          filterByStatuses: statuses,
          subCategories: [],
          modalities: [],
          travelPurposes: [],
        }

        navigateAndStoreFilterOptions(
          filter,
          {
            status: { label: t('Status'), filterType: 'SELECTION', statusEnums: statuses },
            period: {
              label: t('Period'),
              filterType: 'PERIOD_SELECTION',
              dateRangeOption: filter.dates,
            },
          },
          {
            ignorePeriodSize: true,
          }
        )
      })
      .catch(console.error)
  }

  const isEmpty = Object.keys(categorisedTasks).length === 0 && confirmableMobilityEventsCount === 0

  return (
    <div id="tasklist" className={Styles.tasklist}>
      {displayTitle && (
        <Typography className={Styles.title} variant="h1-large" element="p">
          {t('Tasks')}
        </Typography>
      )}

      {isEmpty && (
        <Typography className={Styles.emptyMessage} variant="body-3">
          {t('There are currently no tasks.')}
        </Typography>
      )}

      {Boolean(confirmableMobilityEventsCount) && (
        <Task
          id="confirmable-mobility-events-task"
          index={0}
          title={t('Open declarations', { count: confirmableMobilityEventsCount })}
          message={t('You still have {{ count }}{{ plus }} declarations outstanding.', {
            count: confirmableMobilityEventsCount > 20 ? 20 : confirmableMobilityEventsCount,
            plus: confirmableMobilityEventsCount > 20 ? '+' : '',
          })}
          onClick={openDeclarationsHandler}
        />
      )}

      {Object.keys(categorisedTasks).map((category) => {
        const categoryTasks = categorisedTasks[category]

        return categoryTasks.map((task, index) => {
          const { path } = getModifiedProps(task)
          const title = task.humanized

          return (
            <Task key={index} index={index} title={title} message={task.message} href={path} id={`${task.type}-task`} />
          )
        })
      })}
    </div>
  )
}

export const TaskList: React.FC = (): JSX.Element => {
  const { data, loading, error } = useGetTaskListQuery()

  return <TaskListComponent tasks={data?.me?.tasks} loading={loading} error={error} />
}
