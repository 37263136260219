import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '#/Components'
import { Typography } from '#/Components'

import { ModalContainer } from '../ModalContainer'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ConfirmModal: React.FC<IProps> = ({
  content,
  description,
  cancelContent,
  onCancel,
  confirmButtonProps: { content: buttonContent, ...restButtonProps },
  children,
}): JSX.Element => {
  const { t } = useTranslation()

  const contentClasses: string[] = [Styles.content]
  if (!description) contentClasses.push(Styles.fullPadding)

  return (
    <ModalContainer
      id="confirm-modal"
      title={t('Confirm')}
      className={Styles.modal}
      onClose={(): void => onCancel(true)}
      show
    >
      <Typography className={contentClasses.join(' ')} variant="h2" element="p">
        {content}
      </Typography>

      {description && (
        <Typography className={Styles.description} variant="h2" element="p">
          {description}
        </Typography>
      )}

      {children}

      <Button
        {...restButtonProps}
        id="confirm-button"
        onClick={(mouseEvent): void => {
          mouseEvent.stopPropagation()
          restButtonProps.onClick()
        }}
        {...(restButtonProps.showLegacyButton && { size: 'big' })}
        fullWidth
      >
        {buttonContent}
      </Button>

      <Button
        id="cancel-button"
        data-testid="confirm-modal-cancel-button"
        onClick={(event): void => {
          event.stopPropagation()
          onCancel()
        }}
        variant="secondary"
        size="big"
        textOnly
        fullWidth
      >
        {cancelContent}
      </Button>
    </ModalContainer>
  )
}
